<template>
  <div class="container">
    <img class="title" src="../../assets/images/zqnr.png" alt="">
    <div class="box">
      <img class="icon" @click="toPage('gkdks', 'gkssjskf|gkdks|', '大咖说')" src="../../assets/images/dks.png" alt="">
      <img class="icon" @click="toPage('gkzgx', 'gkssjskf|gkzgx|', '中国行')" src="../../assets/images/zgx.png" alt="">
      <img class="icon" @click="toZxb" src="../../assets/images/blds.png" alt="">
      <img class="icon" @click="toPage('gkqnx', 'gkssjskf|gkqnx|', '青年秀')" src="../../assets/images/qnx.png" alt="">
    </div>
  </div>
</template>

<script>
import mixin from '../../common/mixin/mixins'
export default {
  name: 'CaseCompetitionArea',
  data() {
    return {}
  },
  mixins: [mixin],
  methods: {
    toZxb() {
      this.BuriedPoint(1, '病例大赛', 'zhbblds|', '/zxbCompetition?code=zhbblds');
      this.$router.push('/zxbCompetition?code=zhbblds')
    },
    toPage(Code, TCode, Name,) {
      this.BuriedPoint(1, Name, TCode, '/caseCompetitionList');
      this.$router.push({
        path: '/caseCompetitionList',
        query: {
          code: Code,
          tcode: TCode,
          title: Name
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/blzq_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 10px 0 16px;
  box-sizing: border-box;
  .title {
    width: 90px;
    margin-top: 200px;
    margin-bottom: 6px;
  }
  .box {
    display: flex;
    flex-wrap: wrap;
    img {
      width: calc(100% / 2);
    }
  }
}
</style>